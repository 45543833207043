export default {
    h1: () => import("@/components/base/el/BaseH1"),
    button: () => import("@/components/base/el/BaseButton"),
    h2: () => import("@/components/base/el/BaseH2"),
    h3: () => import("@/components/base/el/BaseH3"),
    h4: () => import("@/components/base/el/BaseH4"),
    h5: () => import("@/components/base/el/BaseH5"),
    h6: () => import("@/components/base/el/BaseH6"),
    p: () => import("@/components/base/el/BaseP"),
    a: () => import("@/components/base/el/BaseA"),
    b: () => import("@/components/base/el/BaseB"),
    del: () => import("@/components/base/el/BaseDel"),
    img: () => import("@/components/base/el/BaseImg"),
    span: () => import("@/components/base/el/BaseSpan"),
    li: () => import("@/components/base/el/BaseLi"),
    small: () => import("@/components/base/el/BaseSmall"),
    special_div: () => import("@/components/base/el/BaseDiv"),
    special_section: () => import("@/components/base/el/BaseSection")
}