import BaseRenderComponent from "@/components/base/BaseRenderComponent";

export default {
    name: "BaseInjectScriptComponent",
    mixins: [BaseRenderComponent],
    created() {
        this.inject_scripts.push(this.script_nodes[0]);
    },
    data() {
        return {
            inject_scripts: []
        }
    },
    computed: {
        html_node() {
            let data = this.$store.state.layout
            let nodes = data.child.filter(node => node.tag === "html")
            return nodes.length > 0 ? nodes[0] : null
        },
        body_node() {
            let data = this.$store.state.layout;
            let parent = this.html_node || data
            let nodes = parent.child.filter(node => node.tag === "body")
            return nodes.length > 0 ? nodes[0] : null
        },
        script_nodes() {
            let data = this.$store.state.layout;
            let parent = this.body_node || data
            return parent.child.filter(node => node.tag === 'script')
        },
    },
    render(createElement) {
        let tag = 'div'
        let data = {
            class: {
                script_nodes: true,
            },
        }
        let children = []

        for (let [index, item] of this.inject_scripts.entries()) {
            let child_options = {}
            if (index === this.inject_scripts.length - 1) {
                child_options = {
                    on: {
                        load: this.inject_next_script,
                        error: this.inject_next_script
                    }
                }
            }
            children.push(
                this.make_element_tree(
                    createElement,
                    item,
                    child_options
                )
            )
        }

        return createElement(
            tag,
            data,
            children
        )
    },
    methods: {
        inject_next_script() {
            let next_index = this.inject_scripts.length
            if (next_index <= this.script_nodes.length - 1) {
                let next_script = this.script_nodes[next_index]
                this.inject_scripts.push(next_script)
                // console.log(this.script_nodes[next_index].attr.src);
            } else {
                // last script pushed, re-trigger window load event to accommodate scripts with $(window).on('load', function)
                window.dispatchEvent(new Event('load'))
            }
        },
    },
}
