import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {
    get_att_data,
    is_array,
    is_object,
} from "@/components/base/utils";
import ComponentMap from "@/components/base/el/ComponentMap";

const BaseSearchResultComponent = () =>
        import("@/components/base/search/BaseSearchResultComponent"),
    BaseEmbedComponent = () =>
        import("@/components/base/BaseEmbedComponent"),
    BaseFilterComponent = () =>
        import("@/components/base/filter/BaseFilterComponent"),
    BaseSearchComponent = () =>
        import("@/components/base/filter/BaseSearchComponent"),
    BaseMultiBlogComponent = () =>
        import("@/components/base/blog/BaseMultiBlogComponent"),
    BaseDetailBlogComponent = () =>
        import("@/components/base/blog/BaseDetailBlogComponent"),
    BaseFormDataComponent = () => import("@/components/base/BaseFormDataComponent"),
    BaseVideoComponent = () => import("@/components/base/BaseVideoComponent"),
    BasePopupComponent = () => import("@/components/base/BasePopupComponent"),
    BaseCarouselComponent = () =>
        import("@/components/base/BaseCarouselComponent"),
    BaseDropdownComponent = () =>
        import("@/components/base/BaseDropdownComponent"),
    BaseHTMLContentComponent = () =>
        import("@/components/base/BaseHTMLContentComponent"),
    BaseSelect2Component = () => import("@/components/base/BaseSelect2Component"),
    BaseNavComponent = () => import("@/components/base/BaseNavComponent"), 
    BaseMenuComponent = () => import("@/components/base/menu/BaseMenuComponent");

export default {
    name: "BaseRenderComponent",
    mixins: [BaseRenderMixin],
    props: {
        component_data: Object,
    },
    mounted() {
        this.$nextTick(() => {
            this.$emit("mounted");
        });
    },
    methods: {
        is_blog_page() {
            if (process.env.NODE_ENV === "development") return false;
            let path = window.parent.location.pathname.split("/");
            return path[path.length - 1] === "blogs";
        },
        is_special_div(element_data) {
            if (element_data.tag !== "div") return false;

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter((c) => c.node === "text" && /\S/.test(c.text)) //text must not be all whitespace
                if (text_nodes.length > 0) return true;
            }

            if (
                element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage
            ) return true

            if (
                element_data.attr &&
                'deepsel-container' in element_data.attr
            ) return true

            return false;
        },
        is_special_section(element_data) {
            if (element_data.tag !== "section") return false;

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter((c) => c.node === "text" && /\S/.test(c.text)) //text must not be all whitespace
                if (text_nodes.length > 0) return true;
            }

            if (
                element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage
            ) return true;

            if (
                element_data.attr &&
                'deepsel-container' in element_data.attr
            ) return true

            return false;
        },
        get_el_props(element_data) {
            let el = element_data.tag;
            let props = null;

            if (element_data.attr) {
                if ('deepsel-menu' in element_data.attr) {
                    el = BaseMenuComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ('deepsel-embed' in element_data.attr) {
                    el = BaseEmbedComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ('deepsel-select2' in element_data.attr) {
                    el = BaseSelect2Component
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ('deepsel-nav' in element_data.attr) {
                    el = BaseNavComponent
                    props = {
                        component_data: element_data
                    }
                    return [el, props]
                }
                if ('deepsel-html-content' in element_data.attr) {
                    el = BaseHTMLContentComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ("deepsel-detail-blog" in element_data.attr) {
                    el = BaseDetailBlogComponent;
                    props = {
                        component_data: element_data
                    };
                    return [el, props];
                }
                if ("deepsel-multi-blog" in element_data.attr) {
                    el = BaseMultiBlogComponent;
                    props = {
                        component_data: element_data,
                    };
                    return [el, props];
                }
                if ("deepsel-multi-search-result" in element_data.attr) {
                    el = BaseSearchResultComponent;
                    props = {
                        component_data: element_data,
                    };
                    return [el, props];
                }
                if ("deepsel-filter" in element_data.attr) {
                    el = BaseFilterComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if (
                    "deepsel-search" in element_data.attr ||
                    (element_data.tag === "input" && element_data.attr.type === "search")
                ) {
                    el = BaseSearchComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if ( (element_data.attr.class && element_data.attr.class.split(" ").includes("modal") ) || "deepsel-popup" in element_data.attr) {
                    el = BasePopupComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if ("is-owl-carousel" in element_data.attr) {
                    el = BaseCarouselComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if (
                    "deepsel-dropdown" in element_data.attr ||
                    (element_data.attr.class &&
                        element_data.attr.class.includes("dropdown-menu"))
                ) {
                    el = BaseDropdownComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if ("deepsel-form-data" in element_data.attr) {
                    el = BaseFormDataComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if ("deepsel-video" in element_data.attr) {
                    el = BaseVideoComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }

            }

            // if (this.$store.state.edit_mode) {
            if (Object.keys(ComponentMap).includes(element_data.tag)) {
                el = ComponentMap[element_data.tag];
                props = {component_data: element_data};
            } else if (this.is_special_div(element_data)) {
                el = ComponentMap.special_div;
                props = {component_data: element_data};
            } else if (this.is_special_section(element_data)) {
                el = ComponentMap.special_section;
                props = {component_data: element_data};
            }
            // }

            return [el, props];
        },
        get_attrs(element_data) {
            let attrs = {};
            if (element_data.attr) {
                // if (element_data.attr.scoped) console.log(element_data.attr);

                for (let key in element_data.attr) {
                    if (key === "style" && is_object(element_data.attr[key])) continue;
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value;
                }

                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(
                    element_data,
                    this.$store.state.common_data
                );

                if (att_data) {
                    for (let key in att_data) {
                        attrs[key] = att_data[key];
                    }
                }

                this.replace_origin_urls(attrs, element_data);

                if (
                    element_data.events &&
                    element_data.events.click["open-link"] &&
                    element_data.tag === "a"
                ) {
                    attrs["href"] = element_data.events.click["open-link"];
                }

                return attrs;
            }
        },
    },
};
