function get_esc_data(component_data, target_data) {
    if (!component_data.attr) return null
    let res
    for (let key in component_data.attr) {
        if (key === 'deepsel-esc') {
            res = match_data(component_data.attr[key], target_data)
        }
    }
    return res
}

function get_padding_data(padding) {
  let padding_arr = padding.split(' ')
  padding_arr = [0,1,2,3].map((e) => {
      if (padding.length === 1) {
          return padding[e]
      } else if (padding.length === 2) {
          if (e === 0 || e === 1) return padding[0]
          else return padding[1]
      } else if (padding.length === 3) {
          if (e === 1 || e === 3) return padding[1]
          else return padding[e]
      } else {
          return padding[e]
      }
  }).map(e => parseInt(e))
  return padding_arr
}
function get_att_data(component_data, target_data) {
    if (!component_data.attr) return null
    let attr = {}

    for (let key in component_data.attr) {

        if (key.slice(0, 12) === 'deepsel-att-') {
            attr[key.slice(12)] = match_data(component_data.attr[key], target_data)

        } else if (key.slice(0, 6) === 't-att-') {
            attr[key.slice(6)] = match_data(component_data.attr[key], target_data)

        }
    }

    return attr
}

function get_filter_from_url_hash(type) {
    let filter_template
    let url_object = new URL(document.URL)
    let hash = url_object.hash.substr(1)
    if (type === 'blog') {
        filter_template = {
            category: null,
            tag: null,
            author: null,
            search: null,
            page: 1
        }
        if (hash) {
            let filter_object = hash
                .split('&')
                .reduce((result, item) => {
                    let parts = item.split('=')
                    result[parts[0]] = parts[1]
                    return result
                }, {});
            for (let key in filter_object) filter_template[key] = decodeURI(filter_object[key])
        }
    } else if (type === 'product') {
        filter_template = {
            search: null,
            price_min: null,
            price_max: null,
            sort: null,
            limit: null,
            category: null,
            category_id: null,
            brand: null,
            brand_id: null,
            tag_id: null,
            collection: null
        }
        if (hash) {
            let filter_object = hash
                .split('&')
                .reduce((result, item) => {
                    let parts = item.split('=')
                    result[parts[0]] = parts[1]
                    return result
                }, {});
            for (let key in filter_object) filter_template[key] = decodeURI(filter_object[key])
        }
    }

    return filter_template
}

function match_data(value, target_data) {
    let res

    switch (value) {
        case 'company.name' || 'company["name"]' || "company['name']":
            res = target_data.company.name
            break
        case 'company.email' || 'company["email"]' || "company['email']":
            res = target_data.company.email
            break
        case 'company.province' || 'company["province"]' || "company['province']":
            res = target_data.company.province
            break
        case 'company.about' || 'company["about"]' || "company['about']":
            res = target_data.company.about
            break
        case 'company.phone' || 'company["phone"]' || "company['phone']":
            res = target_data.company.phone
            break
        case 'company.image' || 'company["image"]' || "company['image']":
            res = target_data.company.image
            break
        case 'company.street' || 'company["street"]' || "company['street']":
            res = target_data.company.street
            break
        case 'user.name' || 'user["name"]' || "user['name']":
            res = target_data.user.name
            break
        case 'user.image' || 'user["image"]' || "user['image']":
            res = target_data.user.image
            break
        case 'active_category':
            res = target_data.active_category
            break
        case 'active_category_id':
            res = target_data.active_category_id
            break
        case 'path':
            res = target_data.path
            break
        case 'website.title' || 'website["title"]' || "website['title']":
            res = target_data.website.title
            break
        case 'website.favicon' || 'website["favicon"]' || "website['favicon']":
            res = target_data.website.favicon
            break
        case 'website.description' || 'website["description"]' || "website['description']":
            res = target_data.website.description
            break
        case 'website.og_type' || 'website["og_type"]' || "website['og_type']":
            res = target_data.website.og_type
            break
        case 'website.og_url' || 'website["og_url"]' || "website['og_url']":
            res = target_data.website.og_url
            break
        case 'website.code_head' || 'website["code_head"]' || "website['code_head']":
            res = target_data.website.code_head
            break
        case 'website.is_prod_page' || 'website["is_prod_page"]' || "website['is_prod_page']":
            res = target_data.website.is_prod_page
            break
        case 'website.canonical' || 'website["canonical"]' || "website['canonical']":
            res = target_data.website.canonical
            break
        case 'website.social_instagram' || 'website["social_instagram"]' || "website['social_instagram']":
            res = target_data.website.social_instagram
            break
        case 'website.social_facebook' || 'website["social_facebook"]' || "website['social_facebook']":
            res = target_data.website.social_facebook
            break
        case 'website.social_youtube' || 'website["social_youtube"]' || "website['social_youtube']":
            res = target_data.website.social_youtube
            break
        case 'website.repay' || 'website["repay"]' || "website['repay']":
            res = target_data.website.repay
            break
        case 'website.terms_of_use' || 'website["terms_of_use"]' || "website['terms_of_use']":
            res = target_data.website.terms_of_use
            break
        case 'website.privacy_policy' || 'website["privacy_policy"]' || "website['privacy_policy']":
            res = target_data.website.title
            break
        default:
            res = null
            break
    }

    return res
}

function is_array(something) {
    return Array.isArray(something);
}

function is_object(something) {
    return (
        typeof something === "object" && !is_array(something) && something !== null
    );
}

function is_string(something) {
    return typeof something === "string";
}

function is_empty(something) {
    if (is_array(something) || is_string(something))
        return something.length === 0;
    else if (is_object(something)) return Object.keys(something).length === 0;
}

// Used with elementIsRendered
function waitUntil(selector, scope, resolve, reject) {
    let loopCount = 0;
    let maxLoops = 100;

    // Loops until element exists in DOM or loop times out
    function checkForElement() {
        if (loopCount === maxLoops) {
            loopCount = 0;
            return reject("Timed out waiting for element to render");
        }

        let el = scope.querySelectorAll(selector);

        setTimeout(() => {
            if (el) {
                loopCount = 0;
                return resolve(el);
            } else {
                loopCount++;
                checkForElement();
            }
        }, 100);
    }

    checkForElement();
}

// Returns a resolved Promise once the selector returns an element
// Useful for when we need to perform an action only when an element is in the DOM
function elementIsRendered(selector, scope = document) {
    return new Promise((resolve, reject) => {
        //start the loop
        return waitUntil(selector, scope, resolve, reject);
    });
}

const isElementInViewport = async function (el) {
    let elLoaded = await elementIsRendered(el);
    if (elLoaded.length < 1) {
        return null;
    }

    let rect = elLoaded[0].getBoundingClientRect();

    return {
        isElementInViewport:
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
            (window.innerHeight ||
                document.documentElement.clientHeight) /* or $(window).height() */ &&
            rect.right <=
            (window.innerWidth ||
                document.documentElement.clientWidth) /* or $(window).width() */,
        rect,
    };
};

function convertImgToBase64(url, callback){
	var canvas = document.createElement('CANVAS');
	var ctx = canvas.getContext('2d');
	var img = new Image;
	img.crossOrigin = 'Anonymous';
	img.onload = function() {
		canvas.height = img.height;
		canvas.width = img.width;
        ctx.drawImage(img,0,0);
        let dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
        canvas = null; 
	}
	img.src = url;
}


export {
    is_array,
    is_object,
    is_string,
    is_empty,
    isElementInViewport,
    get_esc_data,
    get_att_data,
    get_filter_from_url_hash,
    convertImgToBase64,
    get_padding_data
};
