import Vue from "vue";
import Vuex from "vuex";
import store_template from "./store";
import App from "./App.vue";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"
import "@fortawesome/fontawesome-free/css/all.css"

const axios = require("axios").default;

// const moment = require('moment')
// require('moment/locale/vi')

Vue.use(require('vue-moment'))

import VueLodash from "vue-lodash";
import {cloneDeep, isEqual, isEmpty, omit, debounce, merge, get, remove, set, setWith} from "lodash";
import {get_filter_from_url_hash} from "@/components/base/utils";

Vue.prototype.$selector = (s) => document.querySelector(s)
window.sessionStorage.clear();
Vue.use(VueLodash, {
    name: "custom",
    lodash: {cloneDeep, omit, isEqual, isEmpty, debounce, merge, get, remove, set, setWith},
});

Vue.filter("eventComponent", function (value) {
    let event_name;
    switch (value) {
        case "open-dialog":
            event_name = "Mở Popup";
            break;
        case "open-link":
            event_name = "Mở Link";
            break;
        case "apply-filter":
            event_name = "Áp dụng lọc sản phẩm";
            break;
        default:
            event_name = "Not Event";
    }
    return event_name;
});

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(Vuesax, {
    colors: {
        primary: "233,64,87",
    },
});

async function get_store() {
    let store
    if (process.env.NODE_ENV === "development") {
        let res = require("../html_to_json/test/test.json");
        res.state.common_data = require("../demo_data/common.json")
        res.state.common_data.menus = require("../demo_data/menu.json")
        res.state.edit_mode = true

        // res.state.common_data.blog_from_api = true
        store = res
    } else {
        let path = window.parent.location.pathname.split("/");

        let remote_store = Object.assign({}, store_template);
        remote_store.state.edit_mode = path[1] + path[2] === "buildwrap";

        path.shift();
        if (remote_store.state.edit_mode) {
            path.splice(0, 2);
        } else if (path[0] === 'build') {
            path.splice(0, 1);
        }

        let page_path = path.join("/");
        let mail_id
        if (page_path == 'mail') {
            mail_id = new URLSearchParams(window.parent.location.search).get('id')
            remote_store.state.editing_mail = mail_id
        }

        let data = await Promise.all([
            page_path == 'mail' ? axios.get(`/render_data.json?page_path=mail&id=${mail_id}`) : axios.get(`/render_data.json?page_path=${page_path || "home"}`), // layout
            axios.get(`/common.json`), // common
        ])
        let render_data = data[0].data;
        let common_data = data[1].data;

        remote_store.state.origin = render_data.origin;
        remote_store.state.common_data = common_data;
        remote_store.state.page_path = page_path;
        remote_store.state.layout = render_data.layout;
        // return remote_store;
        store = remote_store
    }

    //replace origin tail containing ".html"
    let origin = store.state.origin;
    if (origin) {
        if (origin.slice(origin.length - 5).toLowerCase() === ".html" || origin.slice(origin.length - 4).toLowerCase() === ".htm") {
            origin = origin.replace(/\\/g, "/");
            let fragments = origin.split("/");
            fragments.pop();
            origin = fragments.join("/");
            store.state.origin = origin
        }
    }
    store.state.filter = get_filter_from_url_hash('product')
    store.state.blog_filter = get_filter_from_url_hash('blog')
    store.state.fetched_blogs = false
    store.state.blog_detail = ''
    store.state.activePopups = []
    store.state.isRenderMenu = false
    store.state.loading = true
    store.state.isPopupStyle = false
    store.state.settingMenuDialog = false


    if (store.state.edit_mode) {
        store.state.editing = null
        store.state.editing_setting = null
        store.state.next_editing = null
        store.state.is_dragging = false

        store.state.editing_text = false
        store.state.history_roll = false
        store.state.history_current_index = 0
        store.state.editing_html_content = false
        store.state.snap_x = null
        store.state.clone_component = false
    }


    return store
}

async function Application() {
    try {
        let store = await get_store();
        new Vue({
            render: (h) => h(App),
            store: new Vuex.Store(store),
        }).$mount("#app")
    } catch (e) {
        console.error(e);
    }
}

Application();
