export default {
    state: {
        "editing": null,
        "editing_setting": null,
        "editing_position": "",
        "editing_mail": false,
        "edit_mode": true,
        "filter": {
            "search": null,
            "price_min": null,
            "price_max": null,
            "sort": null,
            "limit": null,
            "category": null,
            "brand": null,
            "tag_id": null
        },
        "products_data": null,
        "common_data": '',
        "inject_scripts": [],
        "layout": '',
        "origin_header": '',
        "origin_footer": '',
        "header_footer_changed": false,
        "activePopups": [
        ]
    },
}