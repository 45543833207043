<template>
  <div id="app" :class="{'deepsel-no-select': $store.state.is_dragging}">
    <EditBar v-if="!render_editor && $store.state.common_data.user "/>
    <QuickEditor v-if="render_editor"/>

    <BaseRenderComponent
        v-if="dev_mode && head_node"
        :component_data="head_node"
    />
    <BaseRenderComponent
        v-for="(item, i) in body_nodes"
        :key="i"
        ref="screenshot"
        :component_data="item"
        @mounted="on_body_mount(i, item)"
    />
    <BaseInjectScriptComponent v-if="body_mounted"/>

    <VerticalSnapLine v-if="$store.state.is_dragging && $store.state.snap_x"/>
    <!--    <HorizontalSnapLine v-if="$store.state.is_dragging && $store.state.snap_y"/>-->

    <component :is="`style`" v-if="render_editor">
      a[href]:not([data-id]):not([href^="#"]):not(.deepsel-editor) { pointer-events: none; }
      *[data-id="{{$store.state.next_editing}}"] {
        outline-style: dashed !important;
        outline-width: 2px !important;
        outline-offset: -2px !important;
        outline-color: #e94057 !important;
      }
    </component>
  </div>
</template>

<script>

const QuickEditor = () => import("@/builder_components/QuickEditor"),
    VerticalSnapLine = () => import("@/builder_components/VerticalSnapLine"),
    EditBar = () => import("@/builder_components/EditBar")
// HorizontalSnapLine = () => import("@/builder_components/HorizontalSnapLine")

// import './assets/cssgram.min.css'
import BaseRenderComponent from "@/components/base/BaseRenderComponent";
import BaseInjectScriptComponent from "@/components/base/BaseInjectScriptComponent";

import {get_filter_from_url_hash} from "@/components/base/utils";

export default {
  name: "App",
  components: {
    BaseRenderComponent,
    BaseInjectScriptComponent,
    QuickEditor,
    VerticalSnapLine,
    EditBar
    // HorizontalSnapLine
  },
  data() {
    return {
      body_mounted: false,
    };
  },
  created() {
    window.onhashchange = this.on_hash_change
  },
  mounted() {
    if (this.html_node && this.html_node.attr) {
      let html = document.getElementsByTagName("html")[0];
      for (let key in this.html_node.attr) {
        html.setAttribute(key, this.html_node.attr[key]);
      }
    }
    if (this.body_node && this.body_node.attr) {
      let body = document.getElementById("app")
      for (let key in this.body_node.attr) {
        body.setAttribute(key, this.body_node.attr[key]);
      }
    }
    if (this.$store.state.edit_mode) {
      document.addEventListener('keydown', this.on_key_down)
    }
  },
  computed: {
    isBlogPage() {
      console.log(this.$store.state.blogDetail);
      return this.$store.state.page_path === 'blog' && this.$store.state.blogDetail
    },
    render_editor() {
      // return false
      let path = window.location.pathname.split("/");
      return (this.dev_mode || path[1] === "build") && this.body_mounted
    },
    dev_mode() {
      return process.env.NODE_ENV === "development";
    },
    html_node() {
      let data = this.$store.state.layout
      let nodes = data.child.filter((node) => node.tag === "html")
      return nodes.length > 0 ? nodes[0] : null
    },
    head_node() {
      let data = this.$store.state.layout;
      let parent = this.html_node || data
      let nodes = parent.child.filter((node) => node.tag === "head")
      return nodes.length > 0 ? nodes[0] : null
    },
    body_node() {
      let data = this.$store.state.layout;
      let parent = this.html_node || data
      let nodes = parent.child.filter((node) => node.tag === "body")
      return nodes.length > 0 ? nodes[0] : null
    },
    body_nodes() {
      let data = this.$store.state.layout;
      let parent = this.body_node || data
      return parent.child.filter((node) => node.tag !== "script")
    },
    history_current_index: {
      get() {
        return this.$store.state.history_current_index
      },
      set(val) {
        this.$store.state.history_current_index = val
      }
    },
    history_length: {
      get() {
        return this.$store.state.history_length
      },
      set(val) {
        this.$store.state.history_length = val
      }
    },
  },
  methods: {
    restore_component_v1(tree, node) {
      if (tree && tree.attr && node && tree.attr["data-id"] === node.attr["data-id"]) {
        // tree = this.lodash.merge(tree, node);
        Object.keys(tree).forEach(k => {
          if (Object.keys(node).includes(k)) tree[k] = node[k]
        })
        return tree;
      } else if (tree && !this.lodash.isEmpty(tree.child)) {
        let result = null;
        for (let index = 0; index < tree.child.length; index++) {
          result = this.restore_component(tree.child[index], node);
          if (result) return result;
        }
        return result;
      }
      return null;
    },
    restore_component(stack, node) {
      if (!stack) return null
      let tree = this.html_node
      let path = stack.path
      node = stack[node]
      let mark = this.lodash.get(tree, path)
      let index = path.pop()
      if (mark) {
        // console.log('MARK', JSON.parse(JSON.stringify(mark)));
        // console.log('NODE', JSON.parse(JSON.stringify(node)));
        if (mark.attr && node.attr && mark.attr['data-id'] && node.attr['data-id'] && mark.attr['data-id'] == node.attr['data-id']) {
          // this.lodash.set(tree, path, node)
          mark = this.lodash.get(tree, path)
          mark.splice(index, 1, node)
          // console.log('NEW_PUSH_1',mark)
        } else {
          mark = this.lodash.get(tree, path)
          this.lodash.isEmpty(node) ?
              mark.splice(index, 1) :
              mark.splice(index, 0, node)
          // console.log('NEW_PUSH_2',mark)
        }
      } else {
        mark = this.lodash.get(tree, path)
        mark.splice(index, 0, node)
        // console.log('TREE',this.$store.state.layout);
        // console.log('NEW_PUSH_3',this.lodash.get(tree, path))
      }

    },
    undo_component() {
      let stacks = sessionStorage
      if (stacks.length > 0 && this.history_current_index > 0) {
        this.history_current_index = this.history_current_index - 1
        // console.log('history_current_index',this.history_current_index);
        return JSON.parse(stacks[this.history_current_index])
      }
    },
    redo_component() {
      let stacks = sessionStorage
      if (stacks.length > this.history_current_index) {
        this.history_current_index = this.history_current_index + 1
        // console.log('history_current_index',this.history_current_index);
        return JSON.parse(stacks[this.history_current_index - 1])
      }
    },
    on_key_down(event) {
      let z_pressed = false
      let y_pressed = false

      if (event.key !== undefined) {
        if (event.key === 'z') z_pressed = true
        else if (event.key === 'y') y_pressed = true
      } else if (event.code !== undefined) {
        if (event.code === 'KeyZ') z_pressed = true
        else if (event.code === 'KeyY') y_pressed = true
      } else if (event.keyCode !== undefined) {
        if (event.keyCode === 90) z_pressed = true
        else if (event.keyCode === 89) y_pressed = true
      }

      if ((event.metaKey || event.ctrlKey) && !this.$store.state.editing_html_content) {
        if (z_pressed) {
          let undo = this.undo_component()
          if (undo) {
            this.$store.state.history_roll = true
            this.restore_component(undo, 'oldV');
          } else this.$store.state.history_roll = false
        } else if (y_pressed) {
          event.preventDefault()
          let redo = this.redo_component()
          if (redo) {
            this.$store.state.history_roll = true
            this.restore_component(redo, 'val');
          } else this.$store.state.history_roll = false
        }
      }
    },
    on_body_mount() {
      // console.log(i + 1 === this.body_nodes.length);
      this.body_mounted = true
      //  i + 1 === this.body_nodes.length
      // setTimeout(() => {
      // }, 4000)
    },
    on_hash_change() {
      this.$store.state.filter = get_filter_from_url_hash('product')
      this.$store.state.blog_filter = get_filter_from_url_hash('blog')
      this.$store.state.fetched_blogs = false
      this.$store.state.loading = this.$vs.loading({type: 'points', color: '26,92,255'})

      setTimeout(() => {
        this.$store.state.loading.close()
      }, 500)
    }
  }
};
</script>

<style lang="scss">
  @import "./assets/main.scss";
</style>

<style>
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus,
input[type="number"]:focus,
button:focus,
a:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  outline: 0 !important;
}

.vs-button--icon .vs-button__content {
  padding: 6px;
}

.vs-button--icon i {
  font-size: inherit !important;
}

.cursor-pointer {
  cursor: pointer;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/Montserrat-Regular.ttf") format("truetype");
}


*[deepsel-detail-product] * {
  font-family: 'Montserrat', sans-serif !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}

.editor {
  font-family: Montserrat, OpenSans, sans-serif;
}

.tooltip-inner {
  font-family: Montserrat, OpenSans, sans-serif;
  font-size: 12px;
  border-radius: 8px !important;
}

.deepsel-focus {
  outline-style: dashed !important;
  outline-width: 2px !important;
  outline-offset: -2px !important;
  outline-color: #e94057 !important;
  transition: none !important;
  /* position: relative !important; */
  /* z-index: 2 !important; */
}

.deepsel-text-edit {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-offset: -2px !important;
  outline-color: limegreen !important;
  box-shadow: none !important;
}

.deepsel-text-edit:focus {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-offset: -2px !important;
  outline-color: limegreen !important;
  box-shadow: none !important;
}

.deepsel-no-select {
  user-select: none;
  -moz-user-select: none;
  cursor: move !important;
}

</style>
