import {get_esc_data, get_att_data, is_object} from "@/components/base/utils";

export default {
    name: 'BaseRenderMixin',
    props: {
        component_data: Object,
    },
    render(createElement) {
        return this.make_element_tree(createElement, this.component_data);
    },
    methods: {
        make_element_tree(createElement, element_data, options = {}, context = null) {
            if (!element_data) return ['']
            let data = {}
            let el, props, attrs, style, children

            // if (element_data.attr && element_data.attr.class && element_data.attr.class.includes('modal')) {
            //     element_data["activePopups"] = [
            //         ...element_data["activePopups"],
            //         ...[{ name: "no name" }]
            //     ]
            // }

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) data.attrs = attrs
            if (style) data.style = style

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data) {
            let el = element_data.tag
            let props = null
            return [el, props]
        },
        get_attrs(element_data) {
            let attrs = {}
            if (element_data.attr) {
                // if (element_data.attr.scoped) console.log(element_data.attr)

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(element_data, this.$store.state.common_data)
                
                if (att_data) {
                    for (let key in att_data) {
                        attrs[key] = att_data[key]
                    }
                }

                this.replace_origin_urls(attrs, element_data)

                if (element_data.events && element_data.events.click['open-link'] && element_data.tag === "a") {
                    attrs['href'] = element_data.events.click['open-link']
                    attrs['target'] = element_data.events.target
                }

                return attrs
            }
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = element_data.attr.style
                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link") {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        get_children(element_data, createElement) {
            let children = []
            let esc_data = get_esc_data(element_data, this.$store.state.common_data)

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        // if (item.tag === "link" && item.attr && item.attr.href) {
                        //     if (item.attr.href.includes("bootstrap")) continue;
                        // }           
                        children.push(this.make_element_tree(createElement, item));


                    } else if (item.node === "text") {
                        let esc_data = get_esc_data(element_data, this.$store.state.common_data)
                        if (esc_data) {
                            children.push(esc_data)
                        } else children.push(item.text);
                    }
                }
                if (!element_data.child.find(e => e.node === "text") && esc_data) {
                    children = [esc_data]
                }
            } else if (esc_data) {
                children.push(esc_data);
            }
            return children
        },
    },
};
